.MuiAppBar-colorPrimary {
  background-color: rgb(181, 184, 232) !important;
  color: #24292e !important;
}

.MuiToolbar-root {
  display: flex;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;

    h6 {
      margin: 0 10px 0 0;
      overflow: hidden;
      max-width: calc(100vw - 238px);
    }

    .MuiCircularProgress-root {
      height: 24px !important;
      width: 24px !important;
    }
  }
}

.MuiDialog-paper {
  table {
    margin: 1em;
    th {
      text-align: right;
      padding: 0 1em 0 0;
    }
  }

  h2 {
    margin: 0 1em;
  }
}

div.tree,
div.contents {
  height: calc(100vh - 64px);
  overflow: auto;
  margin-top: 64px;
  .container {
    padding: 10px;

    .MuiPaper-root {
      padding: 10px;
    }
  }
}

.tree {
  .container {
    margin-left: auto;
    width: max-content;
  }

  .root-title {
    margin: 8px 0;

    span {
      cursor: pointer;
      user-select: none;
    }
  }

  .root-selected {
    & > div {
      margin: 0;

      span {
        font-weight: bold;
      }
    }

    .buttons {
      margin: 0 0 -11px 0;
    }
  }

  button {
    padding: 6px;
  }

  ol {
    list-style-type: none;
    margin: 0;
    padding: 0 0 0 20px;

    li {
      white-space: nowrap;

      &.selected {
        & > div > span {
          font-weight: bold;
        }
        & > .leaf_body {
          margin: -9px 0 -9px 0;
        }
      }

      .buttons {
        margin: -9px 0;
        padding: 0 0 0 32px;
      }

      .leaf_body {
        padding: 8px;

        span {
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }
}

ol.contents {
  list-style-type: none;
  padding: 0;

  li.chapter {
    & > ol {
      list-style-type: none;
      padding: 0;
    }

    .content {
      cursor: pointer;
      user-select: none;
      word-wrap: break-word;

      img {
        max-width: 100%;
      }

      pre {
        white-space: pre-wrap;
      }

      .body {
        font-family: '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3',
          'Hiragino Mincho ProN', serif;
      }

      .hatena {
        h3 > a {
          text-decoration: none;
        }
      }
    }

    hr {
      margin: 10px 0;
    }

    .submit {
      display: flex;
      justify-content: space-between;
    }
  }
}
