body {
  margin: 0;
  background-color: #fff !important;
}

.page-content {
  max-width: 1920px;
  margin: auto;
}

@media (min-width: 1200px) {
  .page-content .key-visual {
    height: 850px;
    width: 100%;
    justify-content: center;
    display: flex;
    overflow: hidden;
  }
}

@media (max-width: 1199px) {
  .page-content .key-visual picture, .page-content .key-visual img {
    width: 100%;
  }
}

.page-content .installed {
  padding: 14px;
}

.page-content .installed img {
  max-width: 100%;
}

.page-content .introduction ol {
  margin-right: 1em;
}

.page-content .introduction ol li {
  list-style-type: none;
}

.page-content .introduction ol li > div {
  font-family: 游明朝, YuMincho, ヒラギノ明朝 ProN W3, Hiragino Mincho ProN, serif;
}

.page-content .login {
  max-width: 800px;
  margin: 0 auto 2em;
}

.page-content .login .logo {
  text-align: center;
}

.page-content .login .logo img {
  margin-bottom: 1em;
}

@media (max-width: 456px) {
  .page-content .login .logo img {
    width: 100%;
  }
}

.page-content .login .buttons {
  text-align: center;
}

.page-content .login .buttons button span {
  text-transform: none !important;
}

footer {
  color: #9e9e9e;
  background-color: #424242;
  flex-flow: wrap;
  padding: 16px 32px;
  display: flex;
}

footer div {
  flex-flow: wrap;
  margin-right: 16px;
  display: flex;
}

footer div a {
  margin: 4px;
  display: block;
}

footer ul {
  padding: 0;
  display: flex;
}

footer ul li {
  margin: 0 16px 0 0;
  list-style-type: none;
}

footer ul li a {
  color: inherit;
  text-decoration: none;
}

.MuiAppBar-colorPrimary {
  color: #24292e !important;
  background-color: #b5b8e8 !important;
}

.MuiToolbar-root {
  justify-content: space-between;
  display: flex;
}

.MuiToolbar-root .title {
  align-items: center;
  display: flex;
}

.MuiToolbar-root .title h6 {
  max-width: calc(100vw - 238px);
  margin: 0 10px 0 0;
  overflow: hidden;
}

.MuiToolbar-root .title .MuiCircularProgress-root {
  height: 24px !important;
  width: 24px !important;
}

.MuiDialog-paper table {
  margin: 1em;
}

.MuiDialog-paper table th {
  text-align: right;
  padding: 0 1em 0 0;
}

.MuiDialog-paper h2 {
  margin: 0 1em;
}

div.tree, div.contents {
  height: calc(100vh - 64px);
  margin-top: 64px;
  overflow: auto;
}

div.tree .container, div.contents .container, div.tree .container .MuiPaper-root, div.contents .container .MuiPaper-root {
  padding: 10px;
}

.tree .container {
  width: max-content;
  margin-left: auto;
}

.tree .root-title {
  margin: 8px 0;
}

.tree .root-title span {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.tree .root-selected > div {
  margin: 0;
}

.tree .root-selected > div span {
  font-weight: bold;
}

.tree .root-selected .buttons {
  margin: 0 0 -11px;
}

.tree button {
  padding: 6px;
}

.tree ol {
  margin: 0;
  padding: 0 0 0 20px;
  list-style-type: none;
}

.tree ol li {
  white-space: nowrap;
}

.tree ol li.selected > div > span {
  font-weight: bold;
}

.tree ol li.selected > .leaf_body {
  margin: -9px 0;
}

.tree ol li .buttons {
  margin: -9px 0;
  padding: 0 0 0 32px;
}

.tree ol li .leaf_body {
  padding: 8px;
}

.tree ol li .leaf_body span {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

ol.contents, ol.contents li.chapter > ol {
  padding: 0;
  list-style-type: none;
}

ol.contents li.chapter .content {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  word-wrap: break-word;
}

ol.contents li.chapter .content img {
  max-width: 100%;
}

ol.contents li.chapter .content pre {
  white-space: pre-wrap;
}

ol.contents li.chapter .content .body {
  font-family: 游明朝, YuMincho, ヒラギノ明朝 ProN W3, Hiragino Mincho ProN, serif;
}

ol.contents li.chapter .content .hatena h3 > a {
  text-decoration: none;
}

ol.contents li.chapter hr {
  margin: 10px 0;
}

ol.contents li.chapter .submit {
  justify-content: space-between;
  display: flex;
}

/*# sourceMappingURL=app.316a3948.css.map */
