body {
  background-color: #ffffff !important;
  margin: 0;
}

.page-content {
  max-width: 1920px;
  margin: auto;

  .key-visual {
    @media (min-width: 1200px) {
      display: flex;
      height: 850px;
      justify-content: center;
      overflow: hidden;
      width: 100%;
    }

    @media (max-width: 1199px) {
      picture,img {
        width: 100%;
      }
    }
  }

  .installed {
    padding: 14px;
    img {
      max-width: 100%;
    }
  }

  .introduction {
    ol {
      margin-right: 1em;

      li {
        list-style-type: none;
        &>div {
          font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", serif;
        }
      }
    }
  }

  .login {
    margin: 0 auto 2em;
    max-width: 800px;

    .logo {
      text-align: center;
      img {
        margin-bottom: 1em;
      }
      @media (max-width: 456px) {
        img {
          width: 100%;
        }
      }
    }

    .buttons {
      text-align: center;

      button {
        span {
          text-transform: none !important;
        }
      }
    }
  }
}

footer {
  background-color: #424242;
  color: #9e9e9e;
  display: flex;
  flex-flow: row wrap;
  padding: 16px 32px;

  div {
    display: flex;
    flex-flow: row wrap;
    margin-right: 16px;

    a {
      display: block;
      margin: 4px;
    }
  }

  ul {
    display: flex;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0 16px 0 0;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
